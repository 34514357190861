/* eslint-disable no-console */
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  Organization,
  OrganizationEdge,
  UserProfile,
  UserProfileEdge
} from 'model/organization'

dayjs.extend(utc)

export const getOrganizationsArray = (
  orgs: OrganizationEdge[]
): Organization[] => {
  if (!orgs || orgs.length === 0) return [] as Organization[]
  const organizations = orgs.map(org => org.node)

  // sort array by updated date - LIFO
  const sortedOrgs: Organization[] = organizations.sort(
    (a: Organization, b: Organization) => {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    }
  )
  return sortedOrgs
}

export const getFormattedDate = (startDate: string | Date): string => {
  if (!startDate) return ''
  const date = dayjs.utc(startDate)
  return date.format('MM-DD-YYYY')
}

export const truncateString = (str: string, maxLength: number): string => {
  if (!str) return ''
  return str.length > maxLength ? `${str.slice(0, maxLength - 3)}...` : str
}

export const employerNameCache = {
  getItem: (userId?: string) => localStorage.getItem(`employer-name-${userId}`),
  setItem: (value: string, userId?: string) =>
    localStorage.setItem(`employer-name-${userId}`, value),
  removeItem: (userId?: string) =>
    localStorage.removeItem(`employer-name-${userId}`)
}

export const getFilteredOrgsByStatus = (
  allOrgs: Organization[],
  status: string
): Organization[] => {
  if (!status || status === 'All') return allOrgs
  const filterParam = status.toLowerCase()
  return allOrgs.filter((org: Organization) => org.status === filterParam)
}

export const getUsersFromEdges = (
  userEdges: UserProfileEdge[]
): UserProfile[] => {
  if (!userEdges || userEdges.length === 0) return [] as UserProfile[]
  const users = userEdges.map(userEdge => userEdge.node)

  // sort array by updated date - LIFO
  const sortedUsers: UserProfile[] = users.sort(
    (a: UserProfile, b: UserProfile) => {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    }
  )

  return sortedUsers
}

export const getFilteredUsersByStatus = (
  allUsers: UserProfile[],
  status: string
): UserProfile[] => {
  if (!status || status === 'All') return allUsers
  const filterParam = status.toLowerCase()
  return allUsers.filter((user: UserProfile) => user.status.toLowerCase() === filterParam)
}

export const getPrettyRoleName = (role: string): string => {
  if (!role) return ''
  switch (role) {
    case 'SUPER_MANAGER':
      return 'Super Manager'
      break
    case 'MANAGER':
      return 'Manager'
      break
    case 'BASIC_USER':
      return 'Basic User'
      break
    case 'FULL_EHI_ACCESS':
      return 'Full EI Access'
      break
    case 'BASIC_EHI_ACCESS':
      return 'Basic EI Access'
      break
    case 'NO_EHI_ACCESS':
      return 'No EI Access'
      break
    default:
      return ''
      break
  }
}
