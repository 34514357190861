/* eslint-disable no-console */
import React from 'react'
import './index.css'
import { CssBaseline } from '@mui/material'

import { StyledEngineProvider } from '@mui/material/styles'
import { theme, fontsToLoad, ThemeInheritor } from 'components'
import { NotificationProvider } from 'context/NotificationContext'
import { addGlobalUncaughtErrorHandler } from 'qiankun'
import { createRoot } from 'react-dom/client'
import { LicenseInfo } from '@mui/x-license-pro'
import { AlertProvider } from 'context/AlertContext'
import * as FullStory from './utils/fullStory'
import * as Segment from './utils/segment'
import * as Mixpanel from './utils/mixpanel'
import App from './App'

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_DATA_GRID_PRO ?? '')
FullStory.init()
Segment.init()
Mixpanel.init()

Promise.all(fontsToLoad).then(() => {
  // eslint-disable-next-line no-console
  addGlobalUncaughtErrorHandler(event => console.error(event))
  const container = document.getElementById('main-root')!
  const root = createRoot(container)
  root.render(
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeInheritor theme={theme}>
          <CssBaseline />
          <NotificationProvider>
            <AlertProvider>
              <App />
            </AlertProvider>
          </NotificationProvider>
        </ThemeInheritor>
      </StyledEngineProvider>
    </React.StrictMode>
  )
})
