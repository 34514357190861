import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import * as impersonationStorage from 'utils/impersonationStorage'
import { relayStylePagination } from '@apollo/client/utilities'
import { getAccessToken } from './authTokenProvider'

const Client = () => {
  const httpLink = createHttpLink({
    uri: `${
      process.env.REACT_APP_MAESTRO_ENDPOINT_URL || 'http://localhost:8082'
    }/query`
  })
  const authLink = setContext((_, { headers }) => {
    const impersonationHeaders = impersonationStorage.isSet()
      ? {
          'Turing-Impersonation-Partner-Code': `${impersonationStorage.get()}`,
          'Turing-Impersonation-Partner-Type': `employer_partner`
        }
      : {}
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${getAccessToken()}`,
        ...impersonationHeaders
      }
    }
  })
  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      addTypename: false,
      typePolicies: {
        Query: {
          fields: {
            employerStaffAccounts: relayStylePagination()
          }
        }
      }
    })
  })
  // eslint-disable-next-line
}
export default Client
