import React, { useEffect, useState } from 'react'
import {
  Button,
  ButtonProps,
  Box,
  Modal,
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  IconButton
} from '@mui/material'
import { styled } from '@mui/material/styles'
import html2canvas from 'html2canvas'
import { jsPDF as JsPDF } from 'jspdf'
import { Color } from 'components/SharedStyles'
import CloseIcon from '@mui/icons-material/Close'

const DownloadButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: '#3148D3',
  color: 'white',
  padding: '13px 17px 13px 20px',
  border: '1px solid #2a3ca5',
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: '#0c196a',
    border: '1px solid #0c196a',
    color: 'white'
  }
}))

interface IProps {
  elementId: string
  pdfName: string
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  downloadClicked: boolean
  setDownloadClicked: React.Dispatch<React.SetStateAction<boolean>>
  setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>
  components: string[]
  selectedComponents: string[]
  setSelectedComponents: React.Dispatch<any>
}

const DownloadDashboardButton = ({
  elementId,
  pdfName,
  isVisible,
  setIsVisible,
  downloadClicked,
  setDownloadClicked,
  setSnackbarOpen,
  components,
  selectedComponents,
  setSelectedComponents
}: IProps) => {
  const [downloaded, setDownloaded] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [counter, setCounter] = useState(0)

  const isClicked: Function = () => {
    setIsVisible(true)
    setDownloadClicked(true)
  }

  const handleChange = (componentName: string) => {
    if (selectedComponents.includes(componentName)) {
      const newSelected = selectedComponents.filter(
        name => name !== componentName
      )
      setSelectedComponents(newSelected)
      return
    }
    setSelectedComponents([...selectedComponents, componentName])
  }

  useEffect(() => {
    if (counter > 0) return
    if (isVisible && downloadClicked && !downloaded) {
      setCounter(counter + 1)
      const date = new Date().toDateString().slice(4).split(' ').join('_')
      const scoreDashboard: any = document.getElementById(`${elementId}`)

      html2canvas(scoreDashboard)
        .then(canvas => {
          const img = canvas.toDataURL('image/png')
          const pdf = new JsPDF('p', 'px')
          pdf.internal.pageSize.width = scoreDashboard.offsetWidth
          pdf.internal.pageSize.height = scoreDashboard.offsetHeight

          pdf.addImage(
            img,
            'PNG',
            10,
            10,
            scoreDashboard.offsetWidth - 40,
            scoreDashboard.offsetHeight - 40
          )
          pdf.save(`${pdfName}_${date}.pdf`)
          setDownloaded(true)
          setSnackbarOpen(true)
          // Clear the downloadClicked event to prevent multiple downloads
          setDownloadClicked(false)
        })
        .then(() => {
          // After PDF is downloaded, reset downloaded event
          setDownloaded(false)
          setSelectedComponents(components)
          setOpenModal(false)
          setCounter(0)
        })
    }
  }, [
    elementId,
    pdfName,
    isVisible,
    downloadClicked,
    setDownloadClicked,
    downloaded,
    setDownloaded,
    setSnackbarOpen,
    setSelectedComponents,
    components,
    selectedComponents,
    counter,
    setCounter
  ])

  return (
    <Box>
      <DownloadButton
        disableRipple
        disabled={downloadClicked}
        onClick={() => setOpenModal(true)}
      >
        Download as PDF
      </DownloadButton>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '600px',
            padding: '64px 24px 24px 24px',
            backgroundColor: Color.white,
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px'
          }}
        >
          <IconButton
            disableRipple
            onClick={() => {
              setOpenModal(false)
              setSelectedComponents(components)
            }}
            sx={{
              position: 'absolute',
              top: 24,
              right: 24,
              padding: 0,
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
          >
            <CloseIcon sx={{ color: 'black', fontSize: '20px' }} />
          </IconButton>
          <Typography variant='h3' sx={{ marginBottom: '26px' }}>
            What would you like to include in your download?
          </Typography>
          <FormControl
            sx={{ marginBottom: '20px', marginLeft: '12px', width: '100%' }}
          >
            <FormGroup>
              {components.map(component => (
                <FormControlLabel
                  key={component}
                  control={
                    <Checkbox
                      disableRipple
                      checked={selectedComponents.includes(component)}
                      onChange={() => handleChange(component)}
                      name={component}
                      sx={{
                        marginRight: '12px',
                        '&:hover': {
                          backgroundColor: 'transparent'
                        }
                      }}
                    />
                  }
                  label={component}
                />
              ))}
            </FormGroup>
          </FormControl>
          <Button
            disableRipple
            disabled={selectedComponents.length === 0}
            onClick={() => setSelectedComponents([])}
            sx={{
              border: 'none',
              marginBottom: '20px',
              marginLeft: '10px',
              padding: 0,
              textDecoration: 'underline',
              fontWeight: 700,
              '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline'
              }
            }}
          >
            Clear All
          </Button>
          <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button
              variant='contained'
              disableRipple
              disabled={downloadClicked || selectedComponents.length === 0}
              onClick={() => {
                if (selectedComponents.length) isClicked(true)
              }}
              sx={{
                backgroundColor: Color.black,
                color: Color.white,
                '&:hover': {
                  backgroundColor: Color.black
                }
              }}
            >
              Confirm
            </Button>
            <Button
              variant='contained'
              disableRipple
              onClick={() => {
                setOpenModal(false)
                setSelectedComponents(components)
              }}
              sx={{
                backgroundColor: Color.grey100,
                color: Color.black,
                marginRight: '16px',
                '&:hover': {
                  backgroundColor: Color.grey100
                }
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default DownloadDashboardButton
