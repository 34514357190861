import { gql } from '@apollo/client'

export const GET_ATS_SYNC_STATUS = gql`
  query GetAtsSyncStatus {
    atsSyncStatus {
      jobs {
        status
        syncedAt
      }
    }
  }
`

export const GET_ATS_JOBS = gql`
  query GetAtsJobs($before: String, $after: String, $first: Int) {
    atsJobs(before: $before, after: $after, first: $first) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          name
          code
          createdAt
          currentApplicants
          totalApplicants
          status
          interviewStages {
            id
            name
            stageOrder
          }
        }
      }
    }
  }
`

export const GET_ATS_JOB_BY_ID = gql`
  query JobWithStats($id: ID!) {
    atsJob(id: $id) {
      name
      id
      code
      createdAt
      currentApplicants
      totalApplicants
      type
      status
      interviewStages {
        id
        name
        stageOrder
      }
      stats {
        race {
          key
          value
        }
        gender {
          key
          value
        }
        disabilityStatus {
          key
          value
        }
        veteranStatus {
          key
          value
        }
      }
      statsByInterviewStages {
        stage {
          id
          name
          stageOrder
        }
        stats {
          gender {
            key
            value
          }
          race {
            key
            value
          }
          disabilityStatus {
            key
            value
          }
          veteranStatus {
            key
            value
          }
        }
      }
    }
  }
`

export const LINKED_ACCOUNT = gql`
  query LinkedAccount($category: String!) {
    linkedAccount(category: $category) {
      id
      integrationName
      contactEmail
    }
  }
`

export const LINK_TOKEN = gql`
  query GetMagicLink($organizationName: String!, $category: String!) {
    linkToken(organizationName: $organizationName, category: $category)
  }
`

export const CREATE_LINKED_ACCOUNT = gql`
  mutation CreateLinkedAccount($publicToken: String!) {
    createLinkedAccount(publicToken: $publicToken) {
      organizationName
      integrationName
    }
  }
`
