/* eslint-disable no-console */
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import CustomRouter from 'routes/CustomRouter'
import { AuthenticationProvider } from 'context/AuthenticationContext'
import { FeatureFlagsProvider } from 'context/FeatureFlagsContext'
import { RouteHistoryProvider } from 'context/RouteHistoryContext'
import Routes from 'routes'
import { IntercomProvider } from 'react-use-intercom'
import {
  Provider as RollbarProvider,
  ErrorBoundary,
  LEVEL_WARN
} from '@rollbar/react'

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID ?? ''
const ROLLBAR_TOKEN = process.env.REACT_APP_ROLLBAR_TOKEN ?? ''
const ROLLBAR_ENV = process.env.REACT_APP_ROLLBAR_ENV ?? ''

const rollbarConfig = {
  accessToken: ROLLBAR_TOKEN,
  environment: ROLLBAR_ENV
}

const history = createBrowserHistory()

const App = (): React.ReactElement => (
  <AuthenticationProvider>
    <ErrorBoundary
      level={LEVEL_WARN}
      errorMessage='Error in React render'
      extra={(error, info) =>
        info.componentStack.includes('Experimental') ? { experiment: true } : {}
      }
    >
      <RollbarProvider config={rollbarConfig}>
        <FeatureFlagsProvider>
          <IntercomProvider appId={INTERCOM_APP_ID}>
            <CustomRouter history={history}>
              <RouteHistoryProvider>
                <Routes />
              </RouteHistoryProvider>
            </CustomRouter>
          </IntercomProvider>
        </FeatureFlagsProvider>
      </RollbarProvider>
    </ErrorBoundary>
  </AuthenticationProvider>
)

export default App
