/* eslint-disable no-console */
import React, { useEffect, useState, useMemo } from 'react'
import { Box, Grid, Typography, Stack, Tooltip } from '@mui/material'
import { Info as InfoIcon } from '@mui/icons-material'
import { Serie } from '@nivo/line'
import {
  GraphData,
  Job,
  PieChartDataPoint,
  HorizontalDataPoint
} from 'model/ats'
import {
  createGraphDataFromJobResponse,
  createHorizontalApplicantData,
  createPieChartApplicantData,
  filterDataByGenderEthnicity
} from 'utils/atsData'
import LoadingIndicator from 'components/LoadingIndicator'
import FilterButton from 'components/FilterButton'
import AtsLineChart from 'components/AtsLineChart'
import AtsPieChart from 'components/AtsPieChart'
import AtsBarChart from 'components/AtsBarChart'

interface JobPageProps {
  jobId: string
  job: Job | null
  jobIsLoading: boolean
  jobError: any
  downloadClicked: boolean
  selectedComponents: string[]
}

const JobPage = ({
  jobId,
  job,
  jobIsLoading,
  jobError,
  downloadClicked,
  selectedComponents
}: JobPageProps) => {
  const [jobData, setJobData] = useState<GraphData>([])
  const [genderPieData, setGenderPieData] = useState<PieChartDataPoint[]>([])
  const [ethnicityPieData, setEthnicityPieData] = useState<PieChartDataPoint[]>(
    []
  )
  const [disabilityData, setDisabilityData] = useState<HorizontalDataPoint>(
    {} as HorizontalDataPoint
  )
  const [lgbtqiaData, setLgbtqiaData] = useState<HorizontalDataPoint>(
    {} as HorizontalDataPoint
  )
  const [veteranData, setVeteranData] = useState<HorizontalDataPoint>(
    {} as HorizontalDataPoint
  )

  // Filter state for ATS Current Totals by stage
  const [filterCurrentGenderValue, setFilterCurrentGenderValue] = useState('')
  const [filteredCurrentGender, setFilteredCurrentGender] = useState<Serie[]>(
    []
  )

  const [filterCurrentEthnicityValue, setFilterCurrentEthnicityValue] =
    useState('')
  const [filteredCurrentEthnicity, setFilteredCurrentEthnicity] = useState<
    Serie[]
  >([])

  const getFilteredApplicantsByGroup = (
    allCandidates: Serie[],
    group: string
  ): Serie[] => {
    if (!group || group === '' || group === 'All') return allCandidates
    const filterParam = group.toString().toLowerCase()
    return allCandidates.filter(
      (candidate: Serie) =>
        candidate.type.toString().toLowerCase() === filterParam ||
        candidate.id.toString().toLowerCase() === filterParam
    )
  }

  useMemo(() => {
    const genderjobData = filterDataByGenderEthnicity(jobData, 'GENDER')
    setFilteredCurrentGender(
      getFilteredApplicantsByGroup(genderjobData, filterCurrentGenderValue)
    )
  }, [filterCurrentGenderValue, jobData])

  useMemo(() => {
    const ethnicityjobData = filterDataByGenderEthnicity(jobData, 'RACE')
    setFilteredCurrentEthnicity(
      getFilteredApplicantsByGroup(
        ethnicityjobData,
        filterCurrentEthnicityValue
      )
    )
  }, [filterCurrentEthnicityValue, jobData])

  useEffect(() => {
    if (job && !jobIsLoading) {
      const graphData = createGraphDataFromJobResponse(job)
      setJobData(graphData)
      const [gPieData, ePieData] = createPieChartApplicantData(job)
      setGenderPieData(gPieData)
      setEthnicityPieData(ePieData)
      const [dData, lData, vData] = createHorizontalApplicantData(job)
      setDisabilityData(dData)
      setLgbtqiaData(lData)
      setVeteranData(vData)
    }
  }, [job, jobIsLoading])

  if (jobIsLoading) return <Box>Loading...</Box>
  if (jobError) return <Box>error</Box>

  // Dowload Button helper - shows and hides component based on if it's selected and it's been clicked
  const showComponent = (name: string) => {
    return (
      (downloadClicked && selectedComponents.includes(name)) || !downloadClicked
    )
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
        {job && job.id ? (
          <Box id='job-page'>
            <Typography variant='h2'>{job.name} - Applicant Data</Typography>
            {showComponent('Gender Funnel') && (
              <Box
                sx={{
                  margin: '50px 0 230px 0',
                  width: '100%',
                  height: '500px',
                  padding: '0px'
                }}
              >
                <Box sx={{ marginBottom: '20px' }}>
                  <Stack direction='row' spacing={1}>
                    <Typography variant='h5'>
                      Gender: Applicant Funnel Chart (Cumulative Line Chart)
                    </Typography>
                    <Tooltip
                      sx={{
                        paddingTop: '4px',
                        fontSize: '16px',
                        '&:hover': { cursor: 'pointer' }
                      }}
                      title='Funnel chart showing total applicants received per stage, for the given date range. For example, if a person is currently in stage 3, they would be counted in stages 1, 2 and 3.'
                      placement='right-start'
                    >
                      <InfoIcon />
                    </Tooltip>
                  </Stack>
                </Box>
                <Box sx={{ margin: '10px 0' }}>
                  <Stack direction='row' justifyContent='space-between'>
                    <Stack direction='row' spacing={1}>
                      <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                        Last Synced Date: November 8, 2024
                      </Typography>
                      <Tooltip
                        sx={{
                          paddingTop: '4px',
                          fontSize: '16px',
                          '&:hover': { cursor: 'pointer' }
                        }}
                        title='Default date range from the creation of the job posting to the last synced date.'
                        placement='right-start'
                      >
                        <InfoIcon />
                      </Tooltip>
                    </Stack>
                    <Stack direction='row' spacing={1}>
                      <FilterButton
                        filterValue={filterCurrentGenderValue}
                        setFilterValue={setFilterCurrentGenderValue}
                        dataLabel='Demographic'
                        options={['All', 'TOTAL', 'men', 'women', 'other']}
                      />
                      {/* <Box
                    sx={{
                      width: '250px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px dashed #191919',
                      borderRadius: '3px'
                    }}
                  >
                    Date Picker
                  </Box> */}
                    </Stack>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    height: 'inherit',
                    padding: '20px',
                    backgroundColor: 'white',
                    borderRadius: '8px'
                  }}
                >
                  <AtsLineChart data={filteredCurrentGender} />
                </Box>
              </Box>
            )}

            {showComponent('Ethnicity Funnel') && (
              <Box
                sx={{
                  margin: '50px 0 230px 0',
                  width: '100%',
                  height: '500px',
                  padding: '0px'
                }}
              >
                <Box sx={{ marginBottom: '20px' }}>
                  <Stack direction='row' spacing={1}>
                    <Typography variant='h5'>
                      Ethnicity: Applicant Funnel Chart (Cumulative Line Chart)
                    </Typography>
                    <Tooltip
                      sx={{
                        paddingTop: '4px',
                        fontSize: '16px',
                        '&:hover': { cursor: 'pointer' }
                      }}
                      title='Funnel chart showing total applicants received per stage, for the given date range. For example, if a person is currently in stage 3, they would be counted in stages 1, 2 and 3.'
                      placement='right-start'
                    >
                      <InfoIcon />
                    </Tooltip>
                  </Stack>
                </Box>
                <Box sx={{ margin: '10px 0' }}>
                  <Stack direction='row' justifyContent='space-between'>
                    <Stack direction='row' spacing={1}>
                      <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                        Last Synced Date: November 8, 2024
                      </Typography>
                      <Tooltip
                        sx={{
                          paddingTop: '4px',
                          fontSize: '16px',
                          '&:hover': { cursor: 'pointer' }
                        }}
                        title='Default date range from the creation of the job posting to the last synced date.'
                        placement='right-start'
                      >
                        <InfoIcon />
                      </Tooltip>
                    </Stack>
                    <Stack direction='row' spacing={1}>
                      <FilterButton
                        filterValue={filterCurrentEthnicityValue}
                        setFilterValue={setFilterCurrentEthnicityValue}
                        dataLabel='Demographic'
                        options={[
                          'All',
                          'TOTAL',
                          'other',
                          'multiracial',
                          'indegenous',
                          'asian',
                          'latine',
                          'black',
                          'white'
                        ]}
                      />
                      {/* <Box
                    sx={{
                      width: '250px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px dashed #191919',
                      borderRadius: '3px'
                    }}
                  >
                    Date Picker
                  </Box> */}
                    </Stack>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    height: 'inherit',
                    padding: '20px',
                    backgroundColor: 'white',
                    borderRadius: '8px'
                  }}
                >
                  <AtsLineChart data={filteredCurrentEthnicity} />
                </Box>
              </Box>
            )}

            {showComponent('Pie Chart') && (
              <Box>
                <Stack direction='row' spacing={3}>
                  <Typography variant='h5'>
                    Total Applicant Demographics
                  </Typography>
                  <Tooltip
                    sx={{
                      paddingTop: '4px',
                      fontSize: '16px',
                      '&:hover': { cursor: 'pointer' }
                    }}
                    title='Total makeup of applicants by EEOC data (gender, race, disability, lgbtqia+, and veteran status).'
                    placement='right-start'
                  >
                    <InfoIcon />
                  </Tooltip>
                </Stack>
                <Box sx={{ padding: '20px 0', marginTop: '20px' }}>
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      border: '1px solid rgb(229, 229, 229)',
                      borderRadius: '8px',
                      padding: '40px'
                    }}
                  >
                    <Grid container sx={{ marginBottom: '100px' }}>
                      {genderPieData && (
                        <Grid item xs={12} md={6}>
                          <AtsPieChart
                            title='Gender'
                            data={genderPieData || []}
                          />
                        </Grid>
                      )}
                      {ethnicityPieData && (
                        <Grid item xs={12} md={6}>
                          <AtsPieChart
                            title='Ethnicity'
                            data={ethnicityPieData || []}
                          />
                        </Grid>
                      )}
                    </Grid>
                    {disabilityData && (
                      <Box sx={{ margin: '20px' }}>
                        <AtsBarChart title='Disability' data={disabilityData} />
                      </Box>
                    )}
                    {lgbtqiaData && (
                      <Box sx={{ margin: '20px' }}>
                        <AtsBarChart title='LGBTQIA+' data={lgbtqiaData} />
                      </Box>
                    )}
                    {veteranData && (
                      <Box sx={{ margin: '20px' }}>
                        <AtsBarChart title='Veteran' data={veteranData} />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        ) : (
          <Box>
            {jobIsLoading ? (
              <LoadingIndicator containerHeight={500} circleSize={100} />
            ) : (
              <Box>
                <Typography>Oops... Something went wrong</Typography>
                {jobError && <pre>{jobError}</pre>}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}
export default JobPage
