import React, { useRef } from 'react'
import { WithGradientBackground } from 'components'
import ServiceTag from 'components/ServiceTag'
import {
  namoraEntry,
  podiumEntry,
  pulsarEntry,
  bumperEntry,
  kcEntry
} from 'utils/entryProvider'
import { Box, BoxProps, styled } from '@mui/material'

interface MicroAppContainerProps extends BoxProps {
  isServicePath: boolean
}

export const MicroAppContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'isServicePath'
})<MicroAppContainerProps>(({ isServicePath: path }) => ({
  display: window.location.pathname && path ? 'block' : 'none'
}))

const MicroServiceContainer = () => {
  const divRef: any = useRef<HTMLDivElement>(null)

  let currentService = ''
  const { pathname } = window.location
  switch (true) {
    case pathname.startsWith('/action-plan'):
      currentService = namoraEntry
      break
    case pathname.startsWith('/dashboard'):
    case pathname.startsWith('/ats'):
    case pathname.startsWith('/hris'):
      currentService = pulsarEntry
      break
    case pathname.startsWith('/equity-index'):
      currentService = bumperEntry
      break
    case pathname.startsWith('/talent-profile'):
    case pathname.startsWith('/knowledge-center'):
      currentService = kcEntry
      break
    case pathname.startsWith('/organization-settings'):
    case pathname.startsWith('/company-dashboard'):
    case pathname === '/':
      currentService = podiumEntry
      break
    case pathname.startsWith('/admin-portal'):
      currentService = 'Admin Portal'
      break
    default:
      currentService = 'Bias Scanner'
  }
  return (
    <main id='main-content' tabIndex={-1}>
      <WithGradientBackground />
      {process.env.NODE_ENV === 'development' && (
        <ServiceTag service={currentService} />
      )}
      <MicroAppContainer
        id='pulsar-root'
        ref={divRef}
        isServicePath={
          pathname.startsWith('/dashboard') ||
          pathname.startsWith('/ats') ||
          pathname.startsWith('/hris')
        }
      />
      <MicroAppContainer
        id='podium-root'
        ref={divRef}
        isServicePath={
          pathname.startsWith('/organization-settings') || pathname === '/'
        }
      />
      <MicroAppContainer
        id='bumper-root'
        ref={divRef}
        isServicePath={
          pathname.startsWith('/equity-index') &&
          pathname !== '/equity-index/employee-survey'
        }
      />
      <MicroAppContainer
        id='kc-root'
        ref={divRef}
        isServicePath={pathname.startsWith('/knowledge-center')}
      />
      <MicroAppContainer
        id='namora-root'
        ref={divRef}
        isServicePath={pathname.startsWith('/action-plan')}
      />
    </main>
  )
}

export default MicroServiceContainer
