import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

const useClearUserPageData = () => {
  const location = useLocation()
  const prevPathnameRef = useRef(location.pathname) // Store the previous pathname

  useEffect(() => {
    const prevPathname = prevPathnameRef.current
    const currentPathname = location.pathname

    // If the previous path was not under "/ats" and the current path is "/ats" or a sub-route, clear localStorage
    if (
      !prevPathname.startsWith('/ats') &&
      currentPathname.startsWith('/ats')
    ) {
      localStorage.removeItem('atsTablePage')
    }

    // Update previous pathname reference for next render
    prevPathnameRef.current = currentPathname
  }, [location.pathname]) // Runs whenever the route changes
}

export default useClearUserPageData
