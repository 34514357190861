/* eslint-disable @typescript-eslint/return-await */
/* eslint-disable no-console */
import { useEffect, useState } from 'react'
import { useLazyQuery, useQuery, useMutation } from '@apollo/client'
import {
  GET_ATS_JOBS,
  GET_ATS_JOB_BY_ID,
  LINKED_ACCOUNT,
  CREATE_LINKED_ACCOUNT,
  LINK_TOKEN,
  GET_ATS_SYNC_STATUS
} from 'context/AtsContext/gql'
import { AtsJobsData, AtsJobsResponse, Job, JobEdge } from 'model/ats'

// maps the jobs response to get all the nodes (Job[])
const getJobsFromResponse = (atsJobsData: AtsJobsData): Job[] => {
  const jobs: Job[] = atsJobsData.atsJobs.edges.map((edge: JobEdge) => {
    const job = edge.node
    return {
      ...job,
      hasApplicants: job.currentApplicants > 0 ? 'ACTIVE' : 'INACTIVE'
    }
  })
  return jobs
}

export const useAts = () => {
  // get ats job by id from BE
  const [
    getAtsJobById,
    { data: atsJobData, error: jobError, loading: jobIsLoading }
  ] = useLazyQuery(GET_ATS_JOB_BY_ID)

  const INITIAL_JOBS_RESPONSE: AtsJobsResponse = {
    data: null
  }

  const [jobs, setJobs] = useState<Job[]>([] as Job[])
  const [jobsIsLoading, setJobsIsLoading] = useState<boolean>(true)
  const [jobsError, setJobsError] = useState<boolean>(false)
  const [linkedAccount, setLinkedAccount] = useState<any>(undefined)
  const [linkToken, setLinkToken] = useState<any>(undefined)
  const [isAtsLinked, setIsAtsLinked] = useState<boolean>(false)
  const [atsLastSynced, setAtsLastSynced] = useState<string | null>(null)
  const [isFetchingAllPages, setIsFetchingAllPages] = useState<boolean>(false)
  const [allJobsCollected, setAllJobsCollected] = useState<boolean>(false)

  // get ats sync status from BE
  const { data: atsSyncStatusData } = useQuery(GET_ATS_SYNC_STATUS)

  // Create a lazy query version for GET_ATS_JOBS
  const [fetchAtsJobs, { loading: lazyJobsLoading }] =
    useLazyQuery(GET_ATS_JOBS)

  // Initial jobs query with pagination
  const {
    data: atsJobsResponse = INITIAL_JOBS_RESPONSE,
    error: atsJobsResponseError,
    loading: initialJobsLoading,
    refetch: refetchAtsJobs
  } = useQuery(GET_ATS_JOBS, {
    variables: { first: 100 } // Fetch first 100 records initially
  })

  // Function to fetch all jobs recursively with pagination
  const fetchAllJobs = async (
    afterCursor?: string,
    collectedJobs: Job[] = []
  ): Promise<Job[]> => {
    try {
      setIsFetchingAllPages(true)

      const result = await fetchAtsJobs({
        variables: {
          first: 100, // Fetch 100 records per request
          after: afterCursor
        }
      })

      if (!result.data?.atsJobs) {
        throw new Error('Failed to fetch jobs data')
      }

      // Extract job nodes from the current page
      const jobsFromCurrentPage = getJobsFromResponse(result.data)

      // Add to our collection
      const updatedJobs = [...collectedJobs, ...jobsFromCurrentPage]

      // Check if we've reached the max limit or if there are no more pages
      if (
        updatedJobs.length >= 1000 ||
        !result.data.atsJobs.pageInfo.hasNextPage
      ) {
        setJobs(updatedJobs)
        setJobsIsLoading(false)
        setIsFetchingAllPages(false)
        setAllJobsCollected(true)
        return updatedJobs
      }

      // If there are more pages, recursively fetch the next page
      return fetchAllJobs(result.data.atsJobs.pageInfo.endCursor, updatedJobs)
    } catch (error) {
      console.error('Error fetching all jobs:', error)
      setJobsError(true)
      setJobsIsLoading(false)
      setIsFetchingAllPages(false)
      return collectedJobs // Return what we have so far
    }
  }

  // get linked account data
  const {
    data: linkedAccountData,
    loading: linkedAccountIsLoading,
    error: linkedAccountError
  } = useQuery(LINKED_ACCOUNT, {
    variables: { category: 'ats' }
  })

  // create linked account
  const [createLinkedAccount] = useMutation(CREATE_LINKED_ACCOUNT, {
    variables: { category: 'ats' },
    // update the cache, this is required so changes are displayed immediately
    update(cache: any, { data: { linkedAccountResponse } }: any): void {
      cache.modify({
        fields: {
          linkedAccount(): any {
            return linkedAccountResponse
          }
        }
      })
    }
  })

  // get Link Token
  const [getLinkToken, { data: mergeLinkToken }] = useLazyQuery(LINK_TOKEN)

  // set linkedAccount
  useEffect(() => {
    if (linkedAccountData) {
      setLinkedAccount(linkedAccountData.linkedAccount)
    }
  }, [linkedAccountData])

  // set link token
  useEffect(() => {
    if (mergeLinkToken) {
      setLinkToken(mergeLinkToken)
    }
  }, [mergeLinkToken])

  // Trigger the fetchAllJobs function when initial data loads
  useEffect(() => {
    // Only start the recursive fetching if we have initial data and not already fetching all pages
    if (
      atsJobsResponse?.atsJobs &&
      !isFetchingAllPages &&
      !allJobsCollected &&
      !jobsError
    ) {
      // Use the endCursor from our initial query to start fetching all remaining pages
      const initialJobs = getJobsFromResponse(atsJobsResponse)
      const { hasNextPage } = atsJobsResponse.atsJobs.pageInfo

      // If there's only one page, we're already done
      if (!hasNextPage) {
        setJobs(initialJobs)
        setJobsIsLoading(false)
        setAllJobsCollected(true)
      } else {
        // Start fetching all remaining pages, beginning with our initial collection
        fetchAllJobs(atsJobsResponse.atsJobs.pageInfo.endCursor, initialJobs)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atsJobsResponse, isFetchingAllPages, jobsError, allJobsCollected])

  // handle jobs error response
  useEffect(() => {
    if (atsJobsResponseError) {
      setJobsError(true)
      setJobsIsLoading(false)
    }
  }, [atsJobsResponseError])

  // set if ats is linked or not
  useEffect(() => {
    if (atsSyncStatusData) {
      const isLinked = !!atsSyncStatusData?.atsSyncStatus?.jobs
      setIsAtsLinked(isLinked)
      if (isLinked) {
        setAtsLastSynced(atsSyncStatusData.atsSyncStatus?.jobs?.syncedAt)
      }
    }
  }, [atsSyncStatusData])

  // Reset fetching state when refetching jobs
  const handleRefetchAllJobs = async () => {
    setJobsIsLoading(true)
    setAllJobsCollected(false)
    setIsFetchingAllPages(false)
    await refetchAtsJobs()
  }

  return {
    refetchAtsJobs: handleRefetchAllJobs,
    jobs,
    jobsIsLoading:
      jobsIsLoading ||
      initialJobsLoading ||
      lazyJobsLoading ||
      isFetchingAllPages,
    jobsError,
    getAtsJobById,
    job: atsJobData?.atsJob,
    jobError,
    jobIsLoading,
    linkedAccount,
    linkedAccountError,
    linkedAccountIsLoading,
    linkToken,
    getLinkToken,
    createLinkedAccount,
    isAtsLinked,
    atsLastSynced
  }
}
