/* eslint-disable react/jsx-no-useless-fragment */
import React, { Box, Typography } from '@mui/material'
import { HorizontalDataPoint } from 'model/ats'

interface AtsBarChartProps {
  title: string
  data: HorizontalDataPoint
}

const AtsBarChart = ({ title, data }: AtsBarChartProps) => {
  const { yes, no, na } = data

  return (
    <>
      {data && (data.yes || data.no || data.na) ? (
        <Box>
          <Typography
            variant='h3'
            sx={{ fontSize: '23px', marginBottom: '15px' }}
          >
            {title}
          </Typography>
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'horizontal',
                width: '100%',
                height: '50px'
              }}
            >
              {data.yes && data.yes !== '0.0' && data.yes !== 0.0 ? (
                <Box
                  sx={{
                    width: `${yes}%`,
                    backgroundColor: '#CEE83F',
                    color: '#000000',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {yes}%
                </Box>
              ) : null}
              {data.no && data.no !== 0.0 && data.no !== '0.0' ? (
                <Box
                  sx={{
                    width: `${no}%`,
                    backgroundColor: '#7D7E9E',
                    color: '#FFFFFF',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {no}%
                </Box>
              ) : null}
              {data.na && data.na !== '0.0' && data.na !== 0.0 ? (
                <Box
                  sx={{
                    width: `${na}%`,
                    backgroundColor: '#E6E6E6',
                    color: '#000000',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {na}%
                </Box>
              ) : null}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'horizontal',
                width: '100%',
                height: '40px'
              }}
            >
              {data.yes && data.yes !== '0.0' && data.yes !== 0.0 ? (
                <Box
                  sx={{
                    width: `${yes}%`,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>
                    YES
                  </Typography>
                </Box>
              ) : null}
              {data.no && data.no !== 0.0 && data.no !== '0.0' ? (
                <Box
                  sx={{
                    width: `${no}%`,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>
                    NO
                  </Typography>
                </Box>
              ) : null}
              {data.na && data.na !== '0.0' && data.na !== 0.0 ? (
                <Box
                  sx={{
                    width: `${na}%`,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>
                    NA
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  )
}
export default AtsBarChart
