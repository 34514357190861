/* eslint-disable no-console */
import React, { useState, useMemo, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import {
  DataGridPro,
  GridEventListener,
  GridPaginationModel,
  GridRowParams
} from '@mui/x-data-grid-pro'
import { useNavigate, useLocation } from 'react-router-dom'
import SearchBar from 'components/SearchBar'
import FilterButton from 'components/FilterButton'
import { Job } from 'model/ats'
import { tableStyles } from './config/styles'
import { jobColumns } from './config'

interface JobsPageProps {
  jobs: Job[]
}

const JobsPage = ({ jobs }: JobsPageProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const userPagignationData = localStorage.getItem('atsTablePage')
  const userPage: number = userPagignationData
    ? JSON.parse(userPagignationData).page
    : 0
  const userPageSize: number = userPagignationData
    ? JSON.parse(userPagignationData).pageSize
    : 10

  const [search, setSearch] = useState('')
  const [activeStatus, setActiveStatus] = useState('ACTIVE')
  const [filteredJobs, setFilteredJobs] = useState<Job[]>(jobs)
  const [page, setPage] = useState<number>(userPage)
  const [pageSize, setPageSize] = useState<number>(userPageSize)

  const handleRowClick: GridEventListener<'rowClick'> = (
    params: GridRowParams
  ) => {
    const jobId = params?.row?.id
    if (jobId) {
      navigate(`/ats/job/${jobId}`)
    }
  }

  const handlePageChange = (newPage: GridPaginationModel) => {
    console.log('changed page:', JSON.stringify(newPage))
    setPage(newPage.page)
    setPageSize(newPage.pageSize)
    const atsPageModel: string = JSON.stringify(newPage)
    localStorage.setItem('atsTablePage', atsPageModel)
  }

  const handleOnChange = (e: any) => {
    setSearch(e.target.value)
  }

  const clearSearch = () => {
    setSearch('')
  }

  const searchFilteredJobs = (jobsArray: Job[]) => {
    return jobsArray.filter((job: Job) =>
      search.toLowerCase() === ''
        ? job
        : job.name.toLowerCase().includes(search.toLowerCase()) ||
          job.code.toLowerCase().includes(search.toLowerCase())
    )
  }

  const getFilteredJobs = (allJobs: Job[], reqStatus: string) => {
    const result = allJobs.filter(job => {
      return (
        !reqStatus || reqStatus === 'All' || job.hasApplicants === reqStatus
      )
    })
    return result
  }

  useEffect(() => {
    const savedPageString = localStorage.getItem('atsTablePage')
    if (savedPageString) {
      const savedPage: GridPaginationModel = JSON.parse(savedPageString)
      setPage(savedPage.page)
      setPageSize(savedPage.pageSize)
    }
  }, [])

  useEffect(() => {
    if (!location.pathname.startsWith('/ats')) {
      localStorage.removeItem('atsTablePage')
    }
  }, [location.pathname])

  useMemo(() => {
    setFilteredJobs(getFilteredJobs(jobs, activeStatus))
  }, [activeStatus, jobs])

  console.log('location path:', location.pathname)

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant='h2' sx={{ textAlign: 'left', marginBottom: '40px' }}>
        Requisitions
      </Typography>
      <Box
        sx={{
          backgroundColor: 'white',
          border: '1px solid rgb(229, 229, 229)',
          borderRadius: '8px',
          padding: '40px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            padding: '20px 5px'
          }}
        >
          <SearchBar
            value={search}
            placeHolderText='Search positions by name, id, or job code'
            onChange={handleOnChange}
            clearSearch={clearSearch}
          />
          <FilterButton
            filterValue={activeStatus}
            setFilterValue={setActiveStatus}
            dataLabel='Status'
            options={['All', 'ACTIVE', 'INACTIVE']}
          />
        </Box>
        <DataGridPro
          sx={tableStyles}
          rows={searchFilteredJobs(filteredJobs)}
          columns={jobColumns}
          pagination
          disableColumnMenu
          initialState={{
            pagination: {
              paginationModel: { page, pageSize }
            }
          }}
          pageSizeOptions={[5, 10, 20]}
          disableRowSelectionOnClick
          onRowClick={handleRowClick}
          onPaginationModelChange={handlePageChange}
        />
      </Box>
    </Box>
  )
}
export default JobsPage
