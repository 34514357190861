import React from 'react'
import {
  DashboardOutlined as DashboardOutlinedIcon,
  InventoryOutlined as InventoryOutlinedIcon,
  BallotOutlined as BallotOutlinedIcon,
  MenuBookOutlined as MenuBookOutlinedIcon,
  QuickreplyOutlined as QuickreplyOutlinedIcon,
  ManageSearchOutlined as ManageSearchOutlinedIcon,
  InsertChartOutlined as InsertChartOutlinedIcon,
  AdminPanelSettings as AdminPanelIcon,
  Add as AddIcon,
  FindInPage as ArticlePreviewIcon,
  Schema as AtsIcon,
} from '@mui/icons-material/'
import { Color } from '@mathison-inc/components'
import { EmployerStaffAccountEhiAccessLevel } from 'model/organization'
import { getAccessToken } from 'utils/authTokenProvider'

export const Navigations = [
  {
    title: 'Strategy',
    noAccess: EmployerStaffAccountEhiAccessLevel.NO_EHI_ACCESS,
    list: [
      {
        label: 'ATS Dashboard',
        icon: <AtsIcon />,
        url: '/ats',
        flagKey: 'enable-ats',
        access: EmployerStaffAccountEhiAccessLevel.FULL_EHI_ACCESS
      },
      {
        label: 'HRIS Dashboard',
        icon: <DashboardOutlinedIcon />,
        url: '/hris',
        flagKey: 'enable_dashboard',
        access: EmployerStaffAccountEhiAccessLevel.FULL_EHI_ACCESS
      },
      {
        label: 'Action Plan',
        icon: <InventoryOutlinedIcon />,
        url: '/action-plan',
        noAccess: EmployerStaffAccountEhiAccessLevel.NO_EHI_ACCESS,
        sublist: [
          {
            label: 'Recommendations',
            url: '/action-plan/recommendations',
            access: EmployerStaffAccountEhiAccessLevel.FULL_EHI_ACCESS
          }
        ]
      },
      {
        label: 'Add Goal',
        icon: <AddIcon sx={{ color: Color.blue400 }} />,
        url: '/action-plan?createGoal=true',
        access: EmployerStaffAccountEhiAccessLevel.FULL_EHI_ACCESS
      }
    ]
  },
  {
    title: 'Assessments',
    access: EmployerStaffAccountEhiAccessLevel.FULL_EHI_ACCESS,
    list: [
      {
        label: 'Equity Index',
        icon: <BallotOutlinedIcon />,
        url: '/equity-index',
        sublist: [
          { label: 'Take Assessment', url: '/equity-index/assessment' },
          {
            label: 'Assessment History',
            checkAssessmentHistory: true,
            url: '/equity-index/history',
            access: EmployerStaffAccountEhiAccessLevel.FULL_EHI_ACCESS
          }
        ]
      }
    ]
  },
  {
    title: 'Resources',
    list: [
      {
        label: 'Knowledge Center',
        icon: <MenuBookOutlinedIcon />,
        url: '/knowledge-center',
        sublist: [
          {
            label: 'Defining & Tracking',
            url: '/knowledge-center/category/defining-tracking-dei/'
          },
          {
            label: 'Attracting & Sourcing',
            url: '/knowledge-center/category/sourcing-attracting/'
          },
          {
            label: 'Interviewing & Engaging',
            url: '/knowledge-center/category/interviewing-engaging/'
          },
          {
            label: 'Onboarding & Advancing',
            url: '/knowledge-center/category/onboarding-advancing/'
          },
          {
            label: 'Advocacy In Action',
            url: '/knowledge-center/advocacy-in-action'
          },
          {
            label: 'Inclusive Leadership',
            url: '/knowledge-center/inclusive-leadership/'
          },
          {
            label: 'Book: Hiring for Diversity',
            url: '/knowledge-center/hiring-for-diversity/'
          }
        ]
      },
      {
        label: 'Bias Scanner',
        icon: <QuickreplyOutlinedIcon />,
        url: '/bias-scanner'
      }
    ]
  },
  {
    title: 'Lab',
    flagKey: 'enable_laboratory',
    list: [
      {
        label: 'AI Bias Scanner',
        icon: <ManageSearchOutlinedIcon />,
        url: `/laboratory?accessToken=${getAccessToken()}`
      },
      {
        label: 'AI EI',
        url: `/laboratory/ai-ei?accessToken=${getAccessToken()}`,
        icon: <InsertChartOutlinedIcon />
      }
    ]
  },
  {
    title: 'Admin',
    noAccess: EmployerStaffAccountEhiAccessLevel.NO_EHI_ACCESS,
    checkIfHasAdminPortalAccess: true,
    list: [
      {
        label: 'Admin Portal',
        icon: <AdminPanelIcon />,
        url: '/admin-portal',
        flagKey: 'enable-new-admin-portal',
        checkIfHasAdminPortalAccess: true
      },
      {
        label: 'Article Preview',
        icon: <ArticlePreviewIcon />,
        url: '/knowledge-center/article-preview',
        flagKey: 'enable-new-admin-portal',
        checkIfHasAdminPortalAccess: true
      }
    ]
  }
]
