/* eslint-disable no-console */
import graphqlClient from './graphqlClient'

export const graphqlRequest = async (query: string, variables = {}) => {
  try {
    const response = await graphqlClient.post('', {
      query,
      variables
    })
    return response.data
  } catch (error) {
    console.error('GraphQL Request Error:', error)
    throw error
  }
}
