/* eslint-disable no-console */
import {
  GraphData,
  GraphDatum,
  GraphStage,
  Job,
  StageStats,
  PieChartDataPoint,
  StatsValue,
  HorizontalDataPoint
} from 'model/ats'

/* eslint-disable @typescript-eslint/no-unused-vars */
const horizontalMappingsArray = [
  {
    id: 'yes',
    typeKey: 'disabilityStatus',
    valueKey: 'YES_I_HAVE_A_DISABILITY_OR_PREVIOUSLY_HAD_A_DISABILITY',
    color: 'hsl(225, 70%, 50%)',
    type: 'DISABILITY',
    targetKeys: [],
    isGrouped: false
  },
  {
    id: 'no',
    typeKey: 'disabilityStatus',
    valueKey: 'NO_I_DONT_HAVE_A_DISABILITY',
    color: 'hsl(225, 70%, 50%)',
    type: 'DISABILITY',
    targetKeys: [],
    isGrouped: false
  },
  {
    id: 'na',
    typeKey: 'disabilityStatus',
    valueKey: 'I_DONT_WISH_TO_ANSWER',
    color: 'hsl(225, 70%, 50%)',
    type: 'DISABILITY',
    targetKeys: ['I_DONT_WISH_TO_ANSWER', 'NO_DATA'],
    isGrouped: true
  },
  {
    id: 'yes',
    typeKey: 'veteranStatus',
    valueKey:
      'I_IDENTIFY_AS_ONE_OR_MORE_OF_THE_CLASSIFICATIONS_OF_A_PROTECTED_VETERAN',
    color: 'hsl(225, 70%, 50%)',
    type: 'VETERAN',
    targetKeys: [],
    isGrouped: false
  },
  {
    id: 'no',
    typeKey: 'veteranStatus',
    valueKey: 'I_AM_NOT_A_PROTECTED_VETERAN',
    color: 'hsl(225, 70%, 50%)',
    type: 'VETERAN',
    targetKeys: [],
    isGrouped: false
  },
  {
    id: 'na',
    typeKey: 'veteranStatus',
    valueKey: 'I_DONT_WISH_TO_ANSWER',
    color: 'hsl(225, 70%, 50%)',
    type: 'VETERAN',
    targetKeys: ['I_DONT_WISH_TO_ANSWER', 'NO_DATA'],
    isGrouped: true
  }
]

const mappingsArray = [
  {
    id: 'men',
    typeKey: 'gender',
    valueKey: 'MALE',
    color: 'hsl(225, 70%, 50%)',
    type: 'GENDER',
    targetKeys: [],
    isGrouped: false
  },
  {
    id: 'women',
    typeKey: 'gender',
    valueKey: 'FEMALE',
    color: 'hsl(338, 70%, 50%)',
    type: 'GENDER',
    targetKeys: [],
    isGrouped: false
  },
  {
    id: 'other',
    typeKey: 'gender',
    valueKey: 'OTHER',
    color: 'hsl(338, 74%, 10%)',
    type: 'GENDER',
    targetKeys: ['OTHER', 'NON_BINARY', 'DECLINE_TO_SELF_IDENTIFY'],
    isGrouped: true
  },
  {
    id: 'na',
    typeKey: 'gender',
    valueKey: 'NO_DATA',
    color: 'hsl(244, 14%, 79%)',
    type: 'GENDER',
    targetKeys: [],
    isGrouped: false
  },
  {
    id: 'white',
    typeKey: 'race',
    valueKey: 'WHITE',
    color: 'hsl(108, 70%, 50%)',
    type: 'RACE',
    targetKeys: [],
    isGrouped: false
  },
  {
    id: 'black',
    typeKey: 'race',
    valueKey: 'BLACK_OR_AFRICAN_AMERICAN',
    color: 'hsl(0, 73%, 50%)',
    type: 'RACE',
    targetKeys: [],
    isGrouped: false
  },
  {
    id: 'latine',
    typeKey: 'race',
    valueKey: 'HISPANIC_OR_LATINO',
    color: 'hsl(33, 76%, 55%)',
    type: 'RACE',
    targetKeys: [],
    isGrouped: false
  },
  {
    id: 'asian',
    typeKey: 'race',
    valueKey: 'ASIAN',
    color: 'hsl(225, 75%, 76%)',
    type: 'RACE',
    targetKeys: [],
    isGrouped: false
  },
  {
    id: 'indegenous',
    typeKey: 'race',
    valueKey: 'AMERICAN_INDIAN_OR_ALASKAN_NATIVE',
    color: 'hsl(297, 70%, 50%)',
    type: 'RACE',
    targetKeys: [],
    isGrouped: false
  },
  {
    id: 'multiracial',
    typeKey: 'race',
    valueKey: 'TWO_OR_MORE_RACES',
    color: 'hsl(254, 12%, 50%)',
    type: 'RACE',
    targetKeys: [],
    isGrouped: false
  },
  {
    id: 'other',
    typeKey: 'race',
    valueKey: 'OTHER',
    color: 'hsl(64, 70%, 50%)',
    type: 'RACE',
    targetKeys: [
      'NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER',
      'DECLINE_TO_SELF_IDENTIFY'
    ],
    isGrouped: true
  },
  {
    id: 'na',
    typeKey: 'race',
    valueKey: 'NO_DATA',
    color: 'hsl(244, 14%, 79%)',
    type: 'RACE',
    targetKeys: [],
    isGrouped: false
  }
]

const createGraphStageDataArray = (
  stageStatsArray: StageStats[]
): GraphDatum[] => {
  const graphData: GraphDatum[] = [] as GraphDatum[]

  mappingsArray.forEach(mapping => {
    const graphStageArray: GraphStage[] = [] as GraphStage[]
    stageStatsArray.forEach(stageStats => {
      const { typeKey, valueKey, targetKeys, isGrouped } = mapping
      const datum: GraphStage = { x: stageStats.stage.name, y: 0 }
      if (isGrouped) {
        datum.y = stageStats.stats[typeKey]
          .filter(item => targetKeys.includes(item.key))
          .reduce((sum, item) => sum + Number(item.value), 0)
      } else {
        datum.y = Number(
          stageStats.stats[typeKey].find(item => item.key === valueKey)
            ?.value ?? 0
        )
      }

      graphStageArray[stageStats.stage.stageOrder] = datum
    })

    const graphStageDatum: GraphDatum = {
      id: mapping.id,
      dateRange: '',
      color: mapping.color,
      type: mapping.type,
      data: graphStageArray
    }

    graphData.push(graphStageDatum)
  })

  // Construct totals graph datum
  const totalsArray: GraphStage[] = []
  stageStatsArray.forEach(stageStats => {
    let sumTotal = 0
    stageStats.stats.gender.forEach(item => {
      sumTotal += Number(item.value)
    })
    const datum: GraphStage = { x: stageStats.stage.name, y: sumTotal }
    totalsArray[stageStats.stage.stageOrder] = datum
  })

  const totalGraphDatum: GraphDatum = {
    id: 'total',
    dateRange: '',
    color: 'hsl(300, 10%, 10%)',
    type: 'TOTAL',
    data: totalsArray
  }

  graphData.push(totalGraphDatum)

  return graphData
}

export const createGraphDataFromJobResponse = (job: Job): GraphData => {
  if (
    job &&
    job.statsByInterviewStages &&
    job.statsByInterviewStages.length > 0
  ) {
    const graphData: GraphData = createGraphStageDataArray(
      job.statsByInterviewStages
    )
    return graphData
  }

  return [] as GraphData
}

export const filterDataByGenderEthnicity = (
  data: any,
  filterType: 'GENDER' | 'RACE'
) => {
  if (!data) {
    return []
  }
  const filteredData: any[] = data.filter(
    (datum: any) => datum.type === filterType || datum.type === 'TOTAL'
  )
  return filteredData
}

export const createPieChartApplicantData = (
  job: Job
): [PieChartDataPoint[], PieChartDataPoint[]] => {
  const pieChartGenderData: PieChartDataPoint[] = []
  const pieChartEthnicityData: PieChartDataPoint[] = []

  if (job && job.stats && job.stats.gender.length > 0) {
    const genderArray: StatsValue[] = job.stats.gender
    const genderMappingsArray = mappingsArray.filter(
      item => item.type === 'GENDER'
    )

    const ethnicityArray: StatsValue[] = job.stats.race
    const ethnicityMappingsArray = mappingsArray.filter(
      item => item.type === 'RACE'
    )

    // Loop through genderMappingsArray and construct the PieChartDataPoint object
    genderMappingsArray.forEach((genderMap, index) => {
      const { id, color, valueKey, targetKeys, isGrouped } = genderMap
      let genderTotal = 0
      if (isGrouped) {
        genderTotal = genderArray
          .filter(gender => targetKeys.includes(gender.key))
          .reduce((sum, gender) => sum + Number(gender.value), 0)
      } else {
        genderTotal = Number(
          genderArray.find(gender => gender.key === valueKey)?.value ?? 0
        )
      }
      const pieData = {
        id: index + 1,
        value: genderTotal,
        label: id,
        color
      }
      pieChartGenderData.push(pieData)
    })

    // Loop through ethnicityMappingsArray and construct the PieChartDataPoint object
    ethnicityMappingsArray.forEach((ethnicityMap, index) => {
      const { id, color, valueKey, targetKeys, isGrouped } = ethnicityMap
      let ethnicityTotal = 0
      if (isGrouped) {
        ethnicityTotal = ethnicityArray
          .filter(race => targetKeys.includes(race.key))
          .reduce((sum, race) => sum + Number(race.value), 0)
      } else {
        ethnicityTotal = Number(
          ethnicityArray.find(race => race.key === valueKey)?.value ?? 0
        )
      }
      const pieData = {
        id: index + 1,
        value: ethnicityTotal,
        label: id,
        color
      }
      pieChartEthnicityData.push(pieData)
    })
  }

  return [pieChartGenderData, pieChartEthnicityData]
}

export const createHorizontalApplicantData = (
  job: Job
): [HorizontalDataPoint, HorizontalDataPoint, HorizontalDataPoint] => {
  const disabilityData: HorizontalDataPoint = {
    yes: 0,
    no: 0,
    na: 0
  }

  const lgbtqiaData: HorizontalDataPoint = {
    yes: 0,
    no: 0,
    na: 0
  }

  const veteranData: HorizontalDataPoint = {
    yes: 0,
    no: 0,
    na: 0
  }

  if (job && job.stats && job.stats.gender.length > 0) {
    const disabilityArray: StatsValue[] = job.stats.disabilityStatus
    const disabilityMappingsArray = horizontalMappingsArray.filter(
      item => item.type === 'DISABILITY'
    )

    const veteranArray: StatsValue[] = job.stats.veteranStatus
    const veteranMappingsArray = horizontalMappingsArray.filter(
      item => item.type === 'VETERAN'
    )

    // Get the total responses to calculate percentage
    const dTotal: number = disabilityArray.reduce(
      (sum, datum) => sum + Number(datum.value),
      0
    )

    // Loop through genderMappingsArray and construct the PieChartDataPoint object
    disabilityMappingsArray.forEach(disabilityMap => {
      const { id, valueKey, targetKeys, isGrouped } = disabilityMap
      let disabilityTotal = 0
      if (isGrouped) {
        disabilityTotal = disabilityArray
          .filter(disability => targetKeys.includes(disability.key))
          .reduce((sum, disability) => sum + Number(disability.value), 0)
      } else {
        disabilityTotal = Number(
          disabilityArray.find(disability => disability.key === valueKey)
            ?.value ?? 0
        )
      }
      if (id === 'yes' || id === 'no' || id === 'na') {
        disabilityData[id] = Math.round((disabilityTotal / dTotal) * 100)
      }
    })

    // Get the total responses to calculate percentage
    const vTotal: number = veteranArray.reduce(
      (sum, datum) => sum + Number(datum.value),
      0
    )

    // Loop through genderMappingsArray and construct the PieChartDataPoint object
    veteranMappingsArray.forEach(veteranMap => {
      const { id, valueKey, targetKeys, isGrouped } = veteranMap
      let veteranTotal = 0
      if (isGrouped) {
        veteranTotal = veteranArray
          .filter(vet => targetKeys.includes(vet.key))
          .reduce((sum, vet) => sum + Number(vet.value), 0)
      } else {
        veteranTotal = Number(
          veteranArray.find(vet => vet.key === valueKey)?.value ?? 0
        )
      }
      if (id === 'yes' || id === 'no' || id === 'na') {
        veteranData[id] = Math.round((veteranTotal / vTotal) * 100)
      }
    })
  }

  return [disabilityData, lgbtqiaData, veteranData]
}
