import React, { createContext, useEffect, useMemo, useState } from 'react'
import mixpanel from 'mixpanel-browser'

import Cookies from 'js-cookie'
import { getAccessToken } from 'utils/authTokenProvider'

export type AuthenticationContextData = {
  isAuthenticated: boolean | undefined
  handleLogout: (message?: string) => void
  logout: () => void
  setIsAuthenticated: (isAuthenticated: boolean) => void
}

export const AuthenticationContext = createContext<AuthenticationContextData>({
  isAuthenticated: undefined,
  handleLogout: () => null,
  logout: () => null,
  setIsAuthenticated: () => null
})

export const AuthenticationProvider = ({ children }: any): JSX.Element => {
  const accessToken = getAccessToken()

  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(
    !!accessToken
  )
  const logout = (user?: any) => {
    if (process.env.REACT_APP_MIXPANEL_TOKEN) {
      if (user) {
        // Send to mixpanel
        mixpanel.track('Session End', {
          user_id: user.id,
          user_name: user.name,
          user_email: user.primaryEmail,
          timestamp: new Date().toISOString(),
          duration: ''
        })
      } else {
        mixpanel.reset()
      }
    }

    localStorage.clear()
    Cookies.remove('tokens')
    window.location.replace(
      `${
        process.env.REACT_APP_IDENTITY_ENTRY ?? 'http://localhost:7106'
      }/logout?redirect_uri=${window.location.origin}`
    )
  }

  useEffect(() => {
    if (isAuthenticated) {
      const currentCookie = Cookies.get('tokens')
      const checkCookie = () => {
        setTimeout(() => {
          if (currentCookie !== Cookies.get('tokens')) {
            if (Cookies.get('tokens') === undefined) {
              logout()
            }
          } else {
            checkCookie()
          }
        }, 5000)
      }
      checkCookie()
    }
  }, [isAuthenticated])

  const store: AuthenticationContextData = useMemo(
    () => ({
      logout,
      isAuthenticated,
      handleLogout: logout,
      setIsAuthenticated
    }),
    [isAuthenticated]
  )

  return (
    <AuthenticationContext.Provider value={store}>
      {children}
    </AuthenticationContext.Provider>
  )
}
