import axios from 'axios'
import { getAccessToken } from 'utils/authTokenProvider'

const createGraphQLClient = (baseURL: string) => {
  const client = axios.create({
    baseURL,
    timeout: 5000,
    headers: {
      'Content-Type': 'application/json'
    }
  })

  // Use a request interceptor to add the Bearer token
  client.interceptors.request.use(
    config => {
      const token = getAccessToken()
      if (token) {
        // Clone the config object instead of mutating it
        const newConfig = {
          ...config,
          headers: new axios.AxiosHeaders({
            ...config.headers,
            Authorization: `Bearer ${token}`
          })
        }
        return newConfig
      }
      return config // Return original config if no token
    },
    error => Promise.reject(error)
  )

  return client
}

// Create the GraphQL client instance
const graphqlClient = createGraphQLClient(
  `${
    process.env.REACT_APP_MAESTRO_ENDPOINT_URL || 'http://localhost:8082'
  }/query`
)

export default graphqlClient
