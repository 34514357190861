/* eslint-disable no-console */
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Box, Typography, Snackbar, SnackbarProps, styled } from '@mui/material'
import { Clear as ClearIcon } from '@mui/icons-material'
import { AtsContext } from 'context/AtsContext'
import LoadingIndicator from 'components/LoadingIndicator'
import Feedback from 'components/Feedback'
import DownloadDashboardButton from 'components/DownloadDashboard'
import JobsPage from '../JobsPage'
import SalesPage from '../SalesPage'
import ErrorPage from '../ErrorPage'
import JobPage from '../JobPage'

export const StyledSnackbar = styled(Snackbar)<SnackbarProps>(() => ({
  backgroundColor: '#050B0E',
  color: 'white',
  borderRadius: '8px',
  boxShadow: '3px 3px 12px 2px rgba(155, 157, 159, 0.3)'
}))

interface LandingPageProps {
  jobId: string | undefined
}

const LandingPage = ({ jobId }: LandingPageProps) => {
  const {
    jobs,
    jobsIsLoading,
    jobsError,
    isAtsLinked,
    atsLastSynced,
    linkedAccount,
    getJobById,
    job,
    jobIsLoading,
    jobError
  } = useContext(AtsContext)

  // TODO: Replace values with actual
  const lastSyncedDate = atsLastSynced || 'not available'
  const integration = linkedAccount?.integrationName

  // Download Dashboard Button Helper states and variables
  const components = ['Gender Funnel', 'Ethnicity Funnel', 'Pie Chart']
  const [isVisible, setIsVisible] = useState(false)
  const [downloadClicked, setDownloadClicked] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [selectedComponents, setSelectedComponents] =
    useState<string[]>(components)

  // TODO: Contact Us Helper
  const contactUs = () => {
    console.log('contact us!')
  }

  useEffect(() => {
    if (jobId) {
      getJobById(jobId)
    }
  }, [jobId, getJobById])

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        position: 'relative',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      {jobsIsLoading ? (
        <LoadingIndicator />
      ) : (
        <Box sx={{ width: '100%' }}>
          <StyledSnackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{ padding: '12px 17px', marginTop: '75px' }}
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}
          >
            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
              PDF Downloaded
              <ClearIcon
                onClick={() => setSnackbarOpen(false)}
                sx={{
                  marginLeft: '20px',
                  fontSize: '16px',
                  '&:hover': { cursor: 'pointer' }
                }}
              />
            </Typography>
          </StyledSnackbar>
          <Box sx={{ width: '100%' }}>
            {isAtsLinked && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  marginBottom: '50px'
                }}
              >
                <Box>
                  <Typography
                    sx={{ textAlign: 'left' }}
                    variant='h1'
                    marginBottom='24px'
                  >
                    ATS Dashboard
                  </Typography>
                  <Typography
                    sx={{ paddingLeft: '3px' }}
                    variant='body2'
                    marginBottom='10px'
                  >
                    Last refreshed{' '}
                    <span
                      style={{ fontWeight: 'bold' }}
                    >{`${lastSyncedDate} `}</span>
                    from{' '}
                    <span style={{ fontWeight: 'bold' }}>{integration}</span>
                  </Typography>
                  <Box>
                    <Feedback />
                  </Box>
                </Box>
                {job && jobId && (
                  <DownloadDashboardButton
                    elementId='job-page'
                    pdfName={job.name}
                    components={components}
                    selectedComponents={selectedComponents}
                    setSelectedComponents={setSelectedComponents}
                    downloadClicked={downloadClicked}
                    setDownloadClicked={setDownloadClicked}
                    isVisible={isVisible}
                    setIsVisible={setIsVisible}
                    setSnackbarOpen={setSnackbarOpen}
                  />
                )}
              </Box>
            )}
          </Box>
          {jobsError ? (
            <Box>
              <ErrorPage onClick={contactUs} />
            </Box>
          ) : (
            <Box>
              {jobId ? (
                <JobPage
                  jobId={jobId}
                  job={job}
                  jobIsLoading={jobIsLoading}
                  jobError={jobError}
                  downloadClicked={downloadClicked}
                  selectedComponents={selectedComponents}
                />
              ) : (
                <Box sx={{ width: '100%' }}>
                  {isAtsLinked ? (
                    <JobsPage jobs={jobs} />
                  ) : (
                    <SalesPage onClick={contactUs} />
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}
export default LandingPage
